/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  graphql,
} from 'gatsby';
import styled from 'styled-components';
import _get from 'lodash/get';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import ContentfulRichText from '../components/ContentfulRichText';
import Layout from '../components/layout';
import Container from '../components/Container';
import {
  BodyText,
  BodyTextBig,
  ContentWrap,
  HeroHeading,
  SectionTitle,
} from '../components/Global';
import SEO from '../components/seo';
import MediaBreakDown from '../styles/MediaBreakDown';
import ImageGallery from '../components/ImageGallery';
// Styles
import { CTA } from '../styles/GlobalStyles';
// Helper
import urlHelper from '../utils/url';


const Hero = styled.div`
  padding: 80px 0;
  text-align: center;
  
  p {
    max-width: 500px;
    width: 100%;
  }
  
  ${MediaBreakDown.lg`
    padding: 50px 0;
  `}
`;

const SectionWrapper = styled.div.attrs({
  className: 'section',
})`
  padding: 80px 0;
  text-align: center;
`;


const Template = (props) => {
  const { location } = props;
  const authUrl = _get(props, 'data.site.siteMetadata.authUrl', '');
  const templateItem = _get(props, 'data.contentfulTemplate', {});
  const seoMeta = {
    title: `${_get(templateItem, 'name', '')} | Template | Workast`,
    description: _get(templateItem, 'tagline', '')
  };
  const templateUrl = urlHelper.getTemplateLibrary(authUrl, _get(templateItem, 'contentful_id', ''));


  return (
    <Layout
      location={location}
      title={_get(templateItem, 'name', '')}
    >
      <SEO
        title={seoMeta.title}
        description={seoMeta.description}
        type="template"
      />
      <Hero>
        <Container>
          <HeroHeading mb="16px">
            {_get(templateItem, 'name', '')}
          </HeroHeading>
          <BodyTextBig mb="40px">
            {_get(templateItem, 'tagline', '')}
          </BodyTextBig>
          <CTA
            target="_blank"
            href={templateUrl}
          >
            Use this template
          </CTA>
        </Container>
      </Hero>
      <Container>
        <ImageGallery
          data={_get(templateItem, 'images', []) || []}
        />
        <ContentWrap mt="80px">
          <ContentfulRichText
            richText={_get(templateItem, 'description', '')}
          />
        </ContentWrap>
      </Container>
      <Container>
        <SectionWrapper>
          <SectionTitle mb="18px">Get started today!</SectionTitle>
          <BodyText mb="40px">Unleash your potential with Workast&apos;s customizable templates</BodyText>
          <CTA
            target="_blank"
            href={templateUrl}
          >
            Use this template
          </CTA>
        </SectionWrapper>
      </Container>
    </Layout>
  );
};

Template.propTypes = {
  data: PropTypes.shape({
    contentfulTemplate: PropTypes.shape({
      description: PropTypes.shape({}),
      images: PropTypes.arrayOf(
        PropTypes.shape({})
      ),
      name: PropTypes.string,
      slug: PropTypes.string,
      tagline: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default Template;

export const pageQuery = graphql`
  query TemplateBySlug($slug: String!) {
    contentfulTemplate(slug: { eq: $slug }) {
      contentful_id
      description {
        raw
      }
      images {
        gatsbyImageData(layout: FULL_WIDTH)
        description
      }
      name
      slug
      tagline
    }
    site {
      siteMetadata {
        authUrl
      }
    }
  }
`;
