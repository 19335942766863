/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import theme from '../../styles/theme';
import MediaBreakDown from '../../styles/MediaBreakDown';
import constants from '../../constants';


const SliderWrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;

  .slick-dots {
    li button:before {
      font-size: 8px;
      opacity: 1;
      color: ${theme.textColor.silverText};
    }
    li.slick-active button:before {
      color: ${theme.background.blue};
    }
  }

  .slick-initialized {
    .slick-track {
      display: flex;
      align-items: stretch;

      .slick-slide {
        display: flex;
        justify-content: center;
        height: initial;
        background: #F7F9FF;
        border-radius: 20px;

        & > div {
          display: flex;

          & > div {
            display: flex !important;
            flex: 1;
          }
        }
      }
    }
  }
`;
SliderWrap.displayName = 'SliderWrap';

const SliderCard = styled.div`
  padding: 20px 40px;
  outline: none;

  ${MediaBreakDown.md`
    padding: 20px;
  `}
`;
SliderCard.displayName = 'SliderCard';
SliderCard.defaultProps = {
  size: constants.SIZES.MEDIUM,
};
SliderCard.propTypes = {
  size: PropTypes.string,
};

const Thumbnail = styled(GatsbyImage)`
  max-width: 700px;
  width: 700px;
  
  ${MediaBreakDown.lg`
    max-width: 80vw;
    width: 80vw;
  `}
`;
Thumbnail.displayName = 'Thumbnail';


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export {
  SliderWrap,
  SliderCard,
  Thumbnail,
};
export default SliderWrap;
