/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React, {
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { v4 } from 'uuid';
import { getImage } from 'gatsby-plugin-image';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import {
  SliderWrap,
  SliderCard,
  Thumbnail,
} from './index.style';


const ImageGallery = ({
  data,
  sliderSettings,
}) => {
  const defaultSettings = {
    infinite: true,
    autoPlay: false,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
  };
  const settings = {
    ...defaultSettings,
    ...sliderSettings,
  };

  const refSlider = useRef();

  useEffect(() => {
    // This is required to make it work. Or it won't start right away. It's a bug:
    setTimeout(() => {
      refSlider.current.slickPlay();
    }, 500);
  }, []);

  return (
    <SliderWrap>
      <Slider
        {...settings}
        ref={refSlider}
      >
        {data.map(item => (
          <SliderCard
            key={v4()}
          >
            <Thumbnail
              image={getImage(item)}
              alt={item.description}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </SliderCard>
        ))}
      </Slider>
    </SliderWrap>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
ImageGallery.defaultProps = {
  sliderSettings: {},
};
ImageGallery.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      gatsbyImageData: PropTypes.shape({}),
    })
  ).isRequired,
  sliderSettings: PropTypes.shape({}),
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default ImageGallery;
